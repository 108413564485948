<template>
  <el-card class="box-card full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('orgName')">
          <el-input v-model="queryForm.orgName" size="small" :placeholder="$t('orgName')"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('account')">
          <el-input v-model="queryForm.name" size="small"></el-input>
        </el-form-item> -->
        <!-- <el-form-item :label="$t('accountType')">
          <el-select v-model="queryForm.orgType" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('directSaleStore')" value="2"></el-option>
            <el-option :label="$t('allianceBusiness')" value="3"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- 区域选择器 -->
        <el-form-item :label="$t('region')">
          <el-select v-model="queryForm.region" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <template v-for="(item,index) in countryList">
              <el-option-group v-if="item.parentId==0" :key="index" :label="item.name">
                <template v-for="(it,i) in countryList">
                  <el-option v-if="it.parentId==item.id" :key="i+index.toString()" :label="it.name" :value="it.id"></el-option>
                </template>
              </el-option-group>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column prop="orgName" fixed="left" :label="$t('orgName')"> </el-table-column>
        <el-table-column prop="orgName" fixed="left" :label="$t('orgType')">
          <template slot-scope="scope">
            <span v-if="scope.row.type==2">{{$t('directSaleStore')}}</span>
            <span v-else-if="scope.row.type==3">{{$t('franchiseStore')}}</span>
            <span v-else>{{scope.row.typeName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="linkPhone" fixed="left" :label="$t('linkPhone')"> </el-table-column>
        <el-table-column prop="orgName" fixed="left" :label="$t('orgAddress')">
          <template slot-scope="scope">{{scope.row.countryName}} {{scope.row.regionName}}</template>
        </el-table-column>
        <el-table-column prop="orgAddress" fixed="left" :label="$t('detailedAddress')" min-width="400" />
      </el-table>
    </div>

    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />
  </el-card>
</template>

<script>
import home from "@/api/home"
export default {
  data () {
    return {
      queryForm: {},
      //table高度，动态设置
      queryTableHeight: String,


      isLoading: false,
      tableData: {},

      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
    }
  },
  computed: { countryList () { return this.$store.state.globalParameter.countryList }, },
  methods: {
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    /**查询列表 */
    getList () {
      let _this = this;
      _this.isLoading = true;
      home.pageBranch({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.tableData = res.data || {};
          _this.isLoading = false;
        }
      })
    },
  },
  mounted () {
    this.$nextTick(() => {
      let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
      this.queryTableHeight = queryTableHeight + 'px';
      this.getList();
    })
  }
}
</script>

<style lang="less" scoped>
</style>